<template>
  <div class="page flex-col">
    <div class="outer1 flex-col">
      <div class="mod2 flex-col">
        <div class="section1 flex-col">
          <div class="main1 flex-col">
            <span class="word2 zoomIn">SMO社媒营销&nbsp;六大优势</span>
            <span class="txt3 zoomIn">
              多角度、多层面，用较短时间快速提升产品知名度，塑造品牌美誉度和公信力
            </span>
          </div>
        </div>
      </div>
      <div class="imgBox">
        <img src="./assets/img/img1.png" alt=""  class="slideInLeft" />
        <img src="./assets/img/img2.png" alt="" class="slideInRight" />
        <img src="./assets/img/img3.png" alt="" class="slideInLeft"/>
        <img src="./assets/img/img4.png" alt="" class="slideInRight"/>
        <img src="./assets/img/img5.png" alt="" class="slideInLeft" />
        <img src="./assets/img/img6.png" alt="" class="slideInRight"/>
      </div>
      <span class="info3">为什么选择我们？</span>
      <div class="mod12 flex-row">
        <div class="box3 flex-col">
          <div class="section4 flex-col">
            <span class="txt8">1</span>
            <span class="paragraph1">
              专业的
              <br />
              数字营销咨询
            </span>
            <div class="bd3 flex-col">
              <span class="txt9">
                以多年的行业经验进行服务内容的规划，对每一个运营项目定制合理服务排期，用数字化的方式对每一个运营节点进行控制，确保运营效果。
              </span>
            </div>
          </div>
        </div>
        <div class="box4 flex-col">
          <div class="block1 flex-col">
            <span class="word10">2</span>
            <span class="infoBox1">
              成熟的
              <br />
              行业解决方案
            </span>
            <div class="wrap1 flex-col">
              <span class="info4">
                针对每个项目进行评估和风险预测，确保方案顺利运营。
              </span>
            </div>
          </div>
        </div>
        <div class="box5 flex-col">
          <div class="bd4 flex-col">
            <span class="word11">3</span>
            <span class="paragraph2">
              优秀的
              <br />
              设计能力
            </span>
            <div class="bd5 flex-col">
              <span class="info5">
                资深的行业设计大师为运营项目进行有视觉冲击力的图文创意设计。
              </span>
            </div>
          </div>
        </div>
        <div class="box6 flex-col">
          <div class="group4 flex-col">
            <span class="info6">4</span>
            <span class="word12">资深写手</span>
            <div class="layer2 flex-col">
              <span class="txt10">
                五年以上资深媒体记者或行业评论员出身的文案编辑，擅长深度文章，尤其是对新媒体行业有长期的服务经验。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style scoped>
.imgBox {
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: 84px auto 0;
}
.imgBox img {
  width: 100%;
  margin: 16px 0;
}
</style>
