<template lang="html">
  <div id="app">
    <app-header></app-header>
    <!-- <transition name="fade" mode="out-in" :duration="100"> -->
      <router-view></router-view>
    <!-- </transition> -->
    <app-footer></app-footer>
  </div>
</template>
<!-- <link rel="stylesheet" href="./fonts/font.css"> -->
<style lang="css" src="./assets/common.css"></style>
<style lang="css" src="./assets/font/font.css"></style>

<script>
import AppHeader from "./components/Public/Header.vue";
import AppFooter from "./components/Public/Footer.vue";
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {};
  },
  mounted() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
    // console.log(666);

    // this.$nextTick(() => {
    //   if (this.isMourning) {
    //     document.body.style = "filter:grayscale(100%);";
    //   }
    // });
  },
  methods: {},
  // watch: {
  //   $route: function () {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //     document.documentElement.scrollTop = 0;
  //   },
  // },
};
</script>

<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.1s;
}
</style>
