<template>
  <div class="page flex-col">
    <div class="section1 flex-col">
      <div class="layer1 flex-col">
        <div class="layer2 flex-col">
          <div class="outer1 flex-col zoomIn">
            <span class="txt1">投小二适用的客户</span>
          </div>
        </div>
      </div>
      <span class="info1">
        需要开户投放头部媒体信息流广告，如:快手、巨量引擎、广点通等平台，同时因为投放应用下载/表单转化/电商而频次较高、体量较大的广告主及代理商。
      </span>
      <div class="layer3 flex-row">
        <span class="word1">代理商</span>
        <img
          class="pic1"
          referrerpolicy="no-referrer"
          src="../assets/img/down.png"
        />
        <span class="info2">广告主</span>
      </div>
      <div class="layer4 flex-col"></div>
      <div class="layer5 flex-row">
        <div
          class="layer6 flex-col"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <span class="word2" v-html="item.lanhutext0"></span>
        </div>
      </div>
      <span class="word3">广告主/代理商的痛点</span>
      <div class="layer7 flex-row">
        <div
          class="box1 flex-col"
          v-for="(item, index) in loopData1"
          :key="index"
        >
          <div class="box2 flex-col">
            <span class="info3" v-html="item.lanhutext0"></span>
            <span class="word4" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <span class="word5">新媒天成·投小二&nbsp;解决方案</span>
      <div class="layer8 flex-row">
        <div
          class="box3 flex-col"
          v-for="(item, index) in loopData2"
          :key="index"
        >
          <div class="section2 flex-col">
            <div class="section3 flex-col">
              <img
                class="img3"
                referrerpolicy="no-referrer"
                :src="item.lanhuimage0"
              />
            </div>
            <span class="word6" v-html="item.lanhutext0"></span>
            <span class="txt2" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <span class="txt3">核心价值</span>
      <div class="layer9 flex-row">
        <img src="./assets/img/socialvalue.webp" style="width: 100%;height:100%" />
        <!-- <div
          class="main1 flex-col"
          v-for="(item, index) in loopData3"
          :key="index"
        >
          <div class="main2 flex-col">
            <span class="word7" v-html="item.lanhutext0"></span>
            <div class="layer10 flex-col"></div>
            <span class="word8" v-html="item.lanhutext1"></span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="section4 flex-col"><div class="main4 flex-col"></div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {lanhutext0: '快手广告'},
        {lanhutext0: '巨星引擎'},
        {lanhutext0: '腾讯广告'},
      ],
      loopData1: [
        {
          lanhutext0: '一对多',
          lanhutext1:
            '优化师跨平台跨账户管理，搭建不同投放计划，重复度高，效率低。',
        },
        {
          lanhutext0: '人力工作繁重',
          lanhutext1:
            '多平台多账号，汇总数据繁杂，易乱易错，人工实时监控，任务繁重，造成投放事故。',
        },
        {
          lanhutext0: 'KPI难衡量',
          lanhutext1: '优化师操作手段匮乏，投放效果KPI完成度，难以清晰量化。',
        },
      ],
      loopData2: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng30c620c18402bf988af382f5221eb84acc994c22465224139c15c35be24b9469',
          lanhutext0: '提升效率效果',
          lanhutext1:
            '帮助优化师从广告运营困境中解脱，自动优化及预警，释放广告投放潜能。',
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7b50f6fdfa346971d8b415ef903df6dc394a5ddda74335f0add65c792693d699',
          lanhutext0: '批量搭建投放',
          lanhutext1: '一天的工作量一小时做完，生成1500个创意仅需15分钟。',
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1404c0bf3ca74d5ba9d087eedf53a56e2414c8f85c1941f8a0fdf66f66c4c942',
          lanhutext0: '跨账户管理',
          lanhutext1:
            '一个智投账号管理多个媒体下的多个账户，绑定媒体账户与同步数据，便捷易操作。',
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc94b3094973936dc21fbe4988fb265b6d7d4f823db22f3a81a47d8f430e625fd',
          lanhutext0: '全链路数据打通',
          lanhutext1:
            '多维度多指标数据，自由组合，一应俱全，广告/素材/人效数据，一目了然。',
        },
      ],
      loopData3: [
        {
          lanhutext0: '广告主/代理商困境',
          lanhutext1:
            '-&nbsp;优化师手动建投放，效率低下<br/>-&nbsp;创意效果难追踪<br/>-&nbsp;数据报表耗费人力，同步不及时<br/>-&nbsp;广告消耗提升不上去<br/>-&nbsp;投放事故频出',
        },
        {
          lanhutext0: '智投功能',
          lanhutext1:
            '-&nbsp;跨账户批量建创意，单次1500条<br/>-&nbsp;管理素材及效果，明确创意方向&nbsp;<br/>-&nbsp;自动同步投放数据，生成报告<br/>-&nbsp;批量搭建创意，素材效果追踪<br/>-&nbsp;自动预警功能，停止异常投放',
        },
        {
          lanhutext0: '对应效果',
          lanhutext1:
            '-&nbsp;极大提升优化师搭建投放效率<br/>-&nbsp;实时指导素材创作方向<br/>-&nbsp;节约人力，避免信息同步延迟<br/>-&nbsp;提升投放效果，增广告消耗<br/>-&nbsp;减少投放事故',
        },
        {
          lanhutext0: '量化数据',
          lanhutext1:
            '-&nbsp;效率提升8倍<br/>-&nbsp;效率提升3倍<br/>-&nbsp;效率提升5倍<br/>-&nbsp;营收提升2倍<br/>-&nbsp;事故率减少70％',
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
