<template>
  <div class="page flex-col">
    <div class="bd1 flex-col">
      <div class="group1 flex-col">
        <div class="wrap1 flex-col">
        </div>
      </div>
      <span class="txt1">公司简介</span>
      <span class="word1">COMPANY&nbsp;PROFILE</span>
      <div class="group2 flex-col"></div>
      <span class="paragraph1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广东新媒天成科技有限公司于2015年6月创立，是一家集内容创作运营、商业化运作及变现、红人经纪的新媒体营销服务公司。致力于为客户提供多维度全方位的新媒体营销综合解决方案，实现广告投放自动化，产品与消费者的精准匹配，帮助合作商低成本获取客户以及提高产品销售和口碑转化。
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新媒天成涵盖了自媒体广告交易、内容创意营销、自媒体版权及艺人经纪，致力于打造完整的新媒体商业生态综合体，帮助企业进行广告传播、数字互动营销、媒体投资管理、公关促销、提升品牌及企业形象。公司目前已经积累了超过50万家新媒体资源，覆盖新浪微博、微信等多个主流新媒体传播平台。
      </span>
      <div class="group3 flex-col">
        <img
          class="img3"
          referrerpolicy="no-referrer"
          src="../assets/img/about.webp"
        />
      </div>
      <span class="info1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司总部位于东莞市南城区众创金融街，目前已有员工200余人，以精准的策略和独到的创意，配备专业团队，以项目小组制服务，为客户打造具有全面竞争优势的优质品牌，与客户形成良好的合作伙伴关系。
      </span>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />