<template>
  <div class="page flex-col" id="index-page">
    <div class="bd1 flex-col">
      <div class="outer1 flex-col pagep page1" id="page1" ref="page1">
        <div class="mod1 flex-col">
          <div class="box1 flex-col">
            <span class="txt4" :class="{ zoomIn: index == 1 }"
              >综合流量营销服务商</span
            >
            <span class="word8" :class="{ zoomIn: index == 1 }">
              新媒天成高效链接了短视频、直播、网红IP、MCN、消费者、电商、广告服务商等核心资源，将众多的行业资源进行垂直整合、资源共享，通过大数据+云计算让广告资源利用率达到最大化，让APC（广告商+平台商+合作商）商业共赢模式的核心价值发挥到最大。
            </span>
            <div
              class="bd4 flex-col"
              :class="{ bounceInUp: index == 1 }"
              style="cursor: pointer"
              @click="jump(1)"
            ></div>
          </div>
        </div>
      </div>
      <div class="outer4 flex-col pagep page2" id="page2" ref="page2">
        <div class="section1 flex-col">
          <div class="bd5 flex-row"><div class="bd6 flex-col"></div></div>
          <div class="bd7 flex-row">
            <span class="word9">社交媒体·营销全案</span>
          </div>
          <div class="bd8 flex-row">
            <div class="wrap1 flex-col">
              <countTo
                :startVal="0"
                :endVal="10"
                :duration="2000"
                suffix="+"
                ref="count1"
                class="info4 font12"
              ></countTo>
              <span class="txt5">10年项目服务经验</span>
            </div>
            <div class="wrap2 flex-col">
              <span class="word10">SMO社媒营销&nbsp;核心服务</span>
              <span class="info5">
                新媒天成社会化媒体营销，构建品牌内涵、产品机理、利益承诺、传播行业资讯、引领消费时尚指导购买决策
              </span>
            </div>
          </div>
          <div class="bd9 flex-row">
            <div class="layer2 flex-col">
              <countTo
                :startVal="0"
                :endVal="200"
                :duration="2000"
                suffix="+"
                ref="count2"
                class="txt6 font12"
              ></countTo>
              <span class="txt7">200人实力派服务团队</span>
            </div>
            <div class="layer3 flex-col">
              <div class="wrap3 flex-col">
                <img
                  class="icon1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo6.png"
                />
                <span class="word11">自媒体内容IP打造</span>
              </div>
            </div>
            <div class="layer4 flex-col">
              <div class="main3 flex-col">
                <img
                  class="label1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo3.png"
                />
                <span class="word12">双微运营</span>
              </div>
            </div>
            <div class="layer5 flex-col">
              <div class="main4 flex-col">
                <img
                  class="label2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo7.png"
                />
                <span class="txt8">KOL传播</span>
              </div>
            </div>
            <div class="layer6 flex-col">
              <div class="group1 flex-col">
                <img
                  class="label3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo1.png"
                />
                <span class="word13">PGC新闻源推广</span>
              </div>
            </div>
          </div>
          <div class="bd10 flex-row">
            <div class="mod2 flex-col">
              <countTo
                :startVal="0"
                :endVal="800"
                :duration="2000"
                suffix="+"
                ref="count3"
                class="info6 font12"
              ></countTo>
              <span class="word14">800+优质企业服务案例</span>
            </div>
            <div class="mod3 flex-col">
              <div class="section2 flex-col">
                <img
                  class="label4"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo3.png"
                />
                <span class="txt9">UGC口碑种草</span>
              </div>
            </div>
            <div class="mod4 flex-col">
              <div class="section3 flex-col">
                <img
                  class="label5"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo8.png"
                />
                <span class="word15">活动策划</span>
              </div>
            </div>
            <div class="mod5 flex-col">
              <div class="block1 flex-col">
                <img
                  class="label6"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo2.png"
                />
                <span class="word16">品牌舆情监控</span>
              </div>
            </div>
            <div class="mod6 flex-col">
              <div class="bd11 flex-col">
                <img
                  class="icon2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/smo5.png"
                />
                <span class="txt10">软文撰写</span>
              </div>
            </div>
          </div>
          <div class="bd12 flex-row">
            <div
              class="mod7 flex-col pointer"
              :class="{ bounceIn: index == 2 }"
            >
              <router-link to="/smomarket">
                <span class="word17">了解详情&nbsp;&gt;&gt;</span>
              </router-link>
            </div>
          </div>
          <span class="txt11">主营业务</span>
        </div>
      </div>

      <div class="outer5 flex-col pagep page3" id="page3" ref="page3">
        <div class="mod8 flex-col">
          <div class="section4 flex-col">
            <div class="box2 flex-col"></div>
            <span class="word18">品牌IP孵化</span>
            <span class="word19">
              以品牌基因为切入点，定制基因裂变系统为客户实现面向消费者的多维度沟通，通过IP运营为企业领袖，代言人，虚拟形象打造话题式、互动式链接碎片化流量的整体解决方案。
            </span>
            <div class="box3 flex-row">
              <div class="mod9 flex-col"></div>
              <div class="mod10 flex-col">
                <div class="section5 flex-col"></div>
                <div class="section6 flex-col"></div>
              </div>
              <div class="mod11 flex-col">
                <div class="box4 flex-row" :class="{ zoomIn: index == 3 }">
                  <div class="layer7 flex-col">
                    <span class="word20">角色</span>
                    <span class="word21">Role</span>
                  </div>
                  <span class="word22">R</span>
                </div>
                <div class="box5 flex-col"></div>
                <div class="box6 flex-col"></div>
                <div class="box7 flex-row" :class="{ zoomIn: index == 3 }">
                  <div class="outer6 flex-col">
                    <span class="txt12">场景</span>
                    <span class="word23">Scene</span>
                  </div>
                  <span class="txt13">S</span>
                </div>
              </div>
              <div class="mod12 flex-col" :class="{ lightSpeedIn: index == 3 }">
                <span class="word24">企业新媒体人格化RCSC模型</span>
              </div>
              <div class="mod13 flex-col">
                <div class="group2 flex-row" :class="{ zoomIn: index == 3 }">
                  <span class="txt14">C</span>
                  <div class="outer7 flex-col">
                    <span class="word25">内容</span>
                    <span class="word26">Content</span>
                  </div>
                </div>
                <div class="group3 flex-row">
                  <div class="outer8 flex-col"></div>
                  <div class="outer9 flex-col"></div>
                </div>
                <div class="group4 flex-row">
                  <div class="bd13 flex-col"></div>
                  <div class="bd14 flex-col"></div>
                </div>
                <div class="group5 flex-row" :class="{ zoomIn: index == 3 }">
                  <span class="word27">C</span>
                  <div class="mod14 flex-col">
                    <span class="txt15">性格</span>
                    <span class="info7">Character</span>
                  </div>
                </div>
              </div>
              <div class="mod15 flex-col"></div>
            </div>
            <div class="box8 flex-row">
              <div class="section7 flex-col"></div>
              <div class="section8 flex-col"></div>
              <div class="section9 flex-col"></div>
              <div class="section10 flex-col"></div>
              <div class="section11 flex-col"></div>
            </div>
            <div class="box9 flex-row">
              <div class="section12 flex-col"></div>
              <div class="section13 flex-col"></div>
              <div class="section14 flex-col"></div>
              <div class="section15 flex-col"></div>
            </div>
            <div
              class="box10 flex-col pointer"
              :class="{ bounceIn: index == 3 }"
            >
              <router-link to="/branding">
                <span class="info8">了解详情&nbsp;&gt;&gt;</span>
              </router-link>
            </div>
          </div>
          <span class="info9">主营业务</span>
          <span class="word28">达人</span>
          <span class="word29">朋友</span>
          <span class="word30">老师</span>
          <span class="info10">价值观</span>
          <span class="txt16">生活方式</span>
          <span class="word31">品味</span>
          <span class="info11">孩子</span>
          <span class="txt17">保姆</span>
          <span class="word32">新知</span>
          <span class="txt18">专业建议</span>
          <span class="word33">购物</span>
          <span class="word34">度假</span>
          <span class="word35">幽默</span>
          <span class="txt19">亲切</span>
          <span class="word36">正经</span>
          <span class="word37">做饭</span>
          <span class="word38">工作</span>
          <span class="info12">卖萌</span>
          <span class="word39">高冷</span>
        </div>
      </div>
      <div class="outer10 flex-col pagep page4" id="page4" ref="page4">
        <div class="outer11 flex-col">
          <div class="section16 flex-row">
            <div class="bd15 flex-col"></div>
          </div>
          <div class="section17 flex-row">
            <span class="word40" :class="{ zoomIn: index == 4 }"
              >投小二·广告投放</span
            >
          </div>
          <div class="section18 flex-row">
            <img
              class="pic2"
              referrerpolicy="no-referrer"
              :class="{ bounceInLeft: index == 4 }"
              src="./assets/img/ticon1.png"
            />
            <img
              class="img1"
              referrerpolicy="no-referrer"
              :class="{ bounceInDown: index == 4 }"
              src="./assets/img/ticon2.png"
            />
            <img
              class="img2"
              referrerpolicy="no-referrer"
              :class="{ bounceInRight: index == 4 }"
              src="./assets/img/ticon3.png"
            />
          </div>
          <div class="section19 flex-row">
            <span class="word41" :class="{ bounceInLeft: index == 4 }"
              >百万级成熟广告投顾全程服务</span
            >
            <span class="info13" :class="{ bounceInDown: index == 4 }"
              >全行业全渠道优势流量资源</span
            >
            <span class="word42" :class="{ bounceInRight: index == 4 }"
              >AI大数据支撑的精准投放系统辅助</span
            >
          </div>
          <div class="section20 flex-row">
            <div
              class="section21 flex-col pointer"
              :class="{ bounceIn: index == 4 }"
            >
              <router-link to="/client">
                <span class="word43">了解详情&nbsp;&gt;&gt;</span>
              </router-link>
            </div>
          </div>
          <span class="word44">主营业务</span>
        </div>
      </div>
      <div class="outer12 flex-col pagep page5" id="page5" ref="page5">
        <div class="main5 flex-col">
          <div class="layer8 flex-row">
            <div class="section22 flex-col"></div>
          </div>
          <div class="layer9 flex-row">
            <span class="word45" :class="{ zoomIn: index == 5 }"
              >创森林·创业培训</span
            >
          </div>

          <div class="section18 flex-row">
            <img
              class="pic2"
              referrerpolicy="no-referrer"
              :class="{ bounceInLeft: index == 5 }"
              src="./assets/img/cicon1.png"
            />
            <img
              class="img1"
              referrerpolicy="no-referrer"
              :class="{ bounceInDown: index == 5 }"
              src="./assets/img/cicon2.png"
            />
            <img
              class="img2"
              referrerpolicy="no-referrer"
              :class="{ bounceInRight: index == 5 }"
              src="./assets/img/cicon3.png"
            />
          </div>
          <div class="layer11 flex-row">
            <span class="word46" :class="{ bounceInLeft: index == 5 }"
              >全国优秀创业导师大咖全程辅导</span
            >
            <span class="info14" :class="{ bounceInDown: index == 5 }"
              >优秀创业项目主理人带练</span
            >
            <span class="word47" :class="{ bounceInRight: index == 5 }"
              >流量运营精品系列课程</span
            >
          </div>
          <div class="layer12 flex-row">
            <div
              class="main6 flex-col pointer"
              :class="{ bounceIn: index == 5 }"
            >
              <router-link to="/faq">
                <span class="info15">了解详情&nbsp;&gt;&gt;</span>
              </router-link>
            </div>
          </div>
          <span class="word48">主营业务</span>
        </div>
      </div>
      <div class="outer13 flex-col pagep page6" id="page6" ref="page6">
        <div class="box11 flex-col">
          <div class="bd16 flex-col">
            <span class="word49" :class="{ zoomIn: index == 6 }">产品矩阵</span>
            <div class="wrap4 flex-row">
              <div
                class="layer13 flex-col"
                :class="{ slideInLeft: index == 6 }"
              >
                <div class="section23 flex-col">
                  <div class="layer14 flex-row">
                    <img
                      class="img5"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pros1.png"
                    />
                    <span class="word50">广告情报局</span>
                  </div>
                  <div class="layer15 flex-col"></div>
                  <span class="word51">
                    智能测算广告的活跃度、效果和投放金额，有效掌握竞争对手投放策略，洞察行业动态趋势。
                  </span>
                </div>
              </div>
              <div
                class="layer16 flex-col"
                :class="{ slideInRight: index == 6 }"
              >
                <div class="box12 flex-col">
                  <div class="wrap5 flex-row">
                    <img
                      class="pic4"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pros2.png"
                    />
                    <span class="info16">电商数据库</span>
                  </div>
                  <div class="wrap6 flex-col"></div>
                  <span class="word52">
                    数据监测，推广排行，选品分析，有效转化。
                  </span>
                </div>
              </div>
            </div>
            <div class="wrap7 flex-row">
              <div class="main7 flex-col" :class="{ slideInLeft: index == 6 }">
                <div class="wrap8 flex-col">
                  <div class="outer14 flex-row">
                    <img
                      class="pic5"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pros3.png"
                    />
                    <span class="word53">社媒分析站</span>
                  </div>
                  <div class="outer15 flex-col"></div>
                  <span class="info17">
                    各社交媒体数据分析及情报分析，实时追踪各数据，深入分析竞品推广策略，及时掌握行业趋势变化。
                  </span>
                </div>
              </div>
              <div class="main8 flex-col" :class="{ slideInRight: index == 6 }">
                <div class="main9 flex-col">
                  <div class="group9 flex-row">
                    <img
                      class="pic6"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pros4.png"
                    />
                    <span class="txt20">投放小管家</span>
                  </div>
                  <div class="group10 flex-col"></div>
                  <span class="info18">跨平台多账号管理；广告投放自动化。</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer16 flex-col pagep page7" id="page7" ref="page7">
        <div class="mod16 flex-col">
          <div class="mod17 flex-row">
            <span class="word54" :class="{ zoomIn: index == 7 }">合作伙伴</span>
          </div>
          <div :class="{ slideInUp: index == 7 }">
            <div class="mod18 flex-row">
              <span class="word55">广告合作客户</span>
            </div>
            <div class="mod19 flex-row">
              <div class="outer17 flex-col">
                <div class="section24 flex-col"></div>
              </div>
              <div class="outer18 flex-col">
                <div class="bd17 flex-col"></div>
              </div>
              <div class="outer19 flex-col">
                <div class="mod20 flex-col"></div>
              </div>
              <div class="outer20 flex-col">
                <div class="main10 flex-col"></div>
              </div>
              <div class="outer21 flex-col">
                <img
                  class="img6"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo8.png"
                />
              </div>
              <div class="outer22 flex-col">
                <div class="box13 flex-col"></div>
              </div>
            </div>
            <div class="mod21 flex-row">
              <div class="mod22 flex-col">
                <div class="bd18 flex-col"></div>
              </div>
              <div class="mod23 flex-col">
                <div class="bd19 flex-col"></div>
              </div>
              <div class="mod24 flex-col">
                <div class="layer17 flex-col"></div>
              </div>
              <div class="mod25 flex-col">
                <div class="box14 flex-col"></div>
              </div>
              <div class="mod26 flex-col">
                <div class="section25 flex-col"></div>
              </div>
              <div class="mod27 flex-col">
                <img
                  class="img7"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo21.png"
                />
              </div>
            </div>
            <div class="mod28 flex-row">
              <span class="info19">数据产品合作商</span>
            </div>
            <div class="mod29 flex-row">
              <div class="wrap9 flex-col">
                <img
                  class="pic7"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo9.png"
                />
              </div>
              <div class="wrap10 flex-col">
                <img
                  class="pic8"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo18.png"
                />
              </div>
              <div class="wrap11 flex-col">
                <div class="layer18 flex-col"></div>
              </div>
              <div class="wrap12 flex-col">
                <div class="block2 flex-col"></div>
              </div>
              <div class="wrap13 flex-col">
                <img
                  class="pic9"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo15.png"
                />
              </div>
              <div class="wrap14 flex-col">
                <div class="mod30 flex-col"></div>
              </div>
            </div>
            <div class="mod31 flex-row">
              <div class="section26 flex-col">
                <div class="outer23 flex-col"></div>
              </div>
              <div class="section27 flex-col">
                <img
                  class="img8"
                  referrerpolicy="no-referrer"
                  src="./assets/img/clogo1.png"
                />
              </div>
              <div class="section28 flex-col">
                <div class="layer19 flex-col"></div>
              </div>
              <div class="section29 flex-col">
                <div class="mod32 flex-col"></div>
              </div>
              <div class="section30 flex-col">
                <div class="section31 flex-col"></div>
              </div>
              <div class="section32 flex-col">
                <span class="info20">……</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dian">
        <ul>
          <li :class="{ da: index == 1 }" @click="jump(0)"></li>
          <li :class="{ da: index == 2 }" @click="jump(1)"></li>
          <li :class="{ da: index == 3 }" @click="jump(2)"></li>
          <li :class="{ da: index == 4 }" @click="jump(3)"></li>
          <li :class="{ da: index == 5 }" @click="jump(4)"></li>
          <li id="btn6" :class="{ da: index == 6 }" @click="jump(5)"></li>
          <li id="btn7" :class="{ da: index == 7 }" @click="jump(6)"></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      // 滚动前，滚动条距文档顶部的距离
      index: 1,
      jumpgroup: [],
      loading: true,
      // mobileFlag: this.GLOBAL.mobileFlag,
    };
  },
  components: { countTo },
  mounted() {
    // console.log('sss',this.mobileFlag())
    this.jumpgroup = document.querySelectorAll(".pagep");
    // chrome and ie（谷歌和IE）
    // window.addEventListener('mousewheel', this.handleScroll, false);
    document
      .getElementById("index-page")
      .addEventListener("mousewheel", this.handleScroll, false);
    // firefox（火狐）
    document
      .getElementById("index-page")
      .addEventListener("DOMMouseScroll", this.handleScroll, false);
    this.jump(0);
  },
  methods: {
    jump(index) {
      this.index = index + 1;
      let total = this.jumpgroup[index].offsetTop + 120;
      // Chrome
      document.body.scrollTop = total;
      // Firefox
      document.documentElement.scrollTop = total;
      // Safari
      window.pageYOffset = total;
    },
    handleScroll(e) {
      // console.log(e);
      //用来判断滚轮是向上滑动还是向下
      let direction = e.deltaY > 0 ? "down" : "up";
      //鼠标滚轮向下或后
      if (this.loading) {
        this.loading = false;
        if (direction == "down") {
          // console.log(this.target);
          if (this.index == 7) {
            this.index = 7;
          } else {
            this.index = this.index + 1;
            this.jump(this.index - 1);
          }
          if (this.index == 2) {
            this.$refs.count1.start();
            this.$refs.count2.start();
            this.$refs.count3.start();
          }
          setTimeout(() => {
            this.loading = true;
          }, 300);
        } else {
          if (this.index == 1) {
            this.index = 1;
          } else {
            this.index = this.index - 1;
            this.jump(this.index - 1);
          }

          if (this.index == 2) {
            this.$refs.count1.start();
            this.$refs.count2.start();
            this.$refs.count3.start();
          }
          setTimeout(() => {
            this.loading = true;
          }, 300);
        }
      }
    },
  },
};
</script>

<style scoped lang="css" src="./assets/index.response.css" />
