<template>
  <div class="page flex-col">
    <div class="outer1 flex-col">
      <div class="box1 flex-col">
        <div class="wrap1 flex-col">
          <div class="mod1 flex-col zoomIn">
            <span class="word1">综合流量营销服务商</span>
            <span class="word2">
              新媒天成高效链接了短视频、直播、网红IP、MCN、消费者、电商、广告服务商等核心资源，将众多的行业资源进行垂直整合、资源共享，通过大数据+云计算让广告资源利用率达到最大化，让APC（广告商+平台商+合作商）商业共赢模式的核心价值发挥到最大。
            </span>
          </div>
        </div>
      </div>
      <div class="box2 flex-col">
        <div class="group1 flex-col">
          <div class="group2 flex-col">
            <!-- <div class="group3 flex-col"></div> -->
          <div class="word8">主营业务</div>

            <div class="word3 zoomIn">社交媒体·营销全案</div>
            <div class="group4 flex-row">
              <countTo
                :startVal="0"
                :endVal="10"
                :duration="3000"
                suffix="+"
                ref="count1"
                class="info1"
              ></countTo>

              <countTo
                :startVal="0"
                :endVal="200"
                :duration="3000"
                suffix="+"
                ref="count2"
                class="info2"
              ></countTo>

              <countTo
                :startVal="0"
                :endVal="800"
                :duration="3000"
                suffix="+"
                ref="count3"
                class="info3"
              ></countTo>
            </div>
            <div class="group5 flex-row">
              <span class="txt1">10年项目服务经验</span>
              <span class="word4">200人实力派服务团队</span>
              <span class="info4">800+优质企业服务案例</span>
            </div>
            <span class="info5">SMO社媒营销&nbsp;核心服务</span>
            <div class="txt2">
              新媒天成社会化媒体营销，构建品牌内涵、产品机理、利益承诺、传播行业资讯、引领消费时尚指导购买决策
            </div>
            <!-- <span class="txt2">
              新媒天成社会化媒体营销，构建品牌内涵、产品机理、利益承诺、传播行业资讯、引领消费时尚指导购买决策
            </span> -->
            <div class="group6 flex-row">
              <div class="picItem">
                <div class="group7 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >自媒体内容IP打造</div
                >
              </div>
              <div class="picItem">
                <div class="group8 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >双微运营</div
                >
              </div>
              <div class="picItem">
                <div class="group9 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >KOL传播</div
                >
              </div>
              <div class="picItem">
                <div class="group10 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >PGC新闻源推广</div
                >
              </div>
            </div>
            <div class="group11 flex-row"></div>
            <div class="group6 flex-row">
              <div class="picItem">
                <div class="group13 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >UGC口碑种草</div
                >
              </div>
              <div class="picItem">
                <div class="group14 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >活动策划</div
                >
              </div>
              <div class="picItem">
                <div class="group15 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >品牌舆情监控</div
                >
              </div>
              <div class="picItem">
                <div class="group16 flex-col"></div>
                <div class="info6" style="transform: scale(0.88)"
                  >软文撰写</div
                >
              </div>
            </div>
            <div class="group18 flex-col">
              <router-link to="/m_smomarket">
                <div class="block1 flex-row">
                  <div class="word7">了解详情</div>
                  <img
                    class="label1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/right.png"
                  />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="box3 flex-col">
        <div class="mod2 flex-col">
          <div class="box4 flex-col">
            <div class="bd1 flex-col"></div>
            <span class="txt5">投小二·广告投放</span>
            <img
              class="img2"
              referrerpolicy="no-referrer"
              src="./assets/img/ticon1.png"
            />
            <span class="info10">百万级成熟广告投顾全程服务</span>
            <img
              class="img3"
              referrerpolicy="no-referrer"
              src="./assets/img/ticon2.png"
            />
            <span class="info11">全行业全渠道优势流量资源</span>
            <img
              class="img4"
              referrerpolicy="no-referrer"
              src="./assets/img/ticon3.png"
            />
            <span class="info12">AI大数据支撑的精准投放系统辅助</span>
            <div class="bd2 flex-col">
              <router-link to="/m_client">
                <div class="main1 flex-row">
                  <span class="info13">了解详情</span>
                  <img
                    class="label2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/right.png"
                  /></div
              ></router-link>
            </div>
          </div>
          <span class="txt6">主营业务</span>
        </div>
      </div>
      <div class="box5 flex-col">
        <div class="layer1 flex-col">
          <div class="main2 flex-col">
            <div class="section1 flex-col"></div>
            <span class="txt7">创森林·创业培训</span>
            <img
              class="pic2"
              referrerpolicy="no-referrer"
              src="./assets/img/cicon1.png"
            />
            <span class="word9">全国优秀创业导师大咖全程辅导</span>
            <img
              class="pic3"
              referrerpolicy="no-referrer"
              src="./assets/img/cicon2.png"
            />
            <span class="word10">优秀创业项目主理人带练</span>
            <img
              class="pic4"
              referrerpolicy="no-referrer"
              src="./assets/img/cicon3.png"
            />
            <span class="word11">流量运营精品系列课程</span>
            <div class="section2 flex-col">
              <router-link to="/m_faq">
                <div class="group19 flex-row">
                  <span class="word12">了解详情</span>
                  <img
                    class="label3"
                    referrerpolicy="no-referrer"
                    src="./assets/img/right.png"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <span class="word13">主营业务</span>
        </div>
      </div>
      <div class="box6 flex-col">
        <div class="wrap2 flex-col">
          <div class="bd3 flex-col">
            <span class="word14">产品矩阵</span>
            <div class="group20 flex-col">
              <div
                class="box7 flex-col"
                v-for="(item, index) in loopData0"
                :key="index"
              >
                <div class="section3 flex-col">
                  <div class="layer2 flex-row">
                    <img
                      class="icon1"
                      referrerpolicy="no-referrer"
                      :src="item.lanhuimage0"
                    />
                    <span class="info14" v-html="item.lanhutext0"></span>
                  </div>
                  <div
                    class="layer3 flex-col"
                    :style="{ background: item.lanhuBg3 }"
                  ></div>
                  <span class="txt8" v-html="item.lanhutext1"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box8 flex-col">
        <div class="main3 flex-col">
          <div class="layer4 flex-row">
            <span class="word15">合作伙伴</span>
          </div>
          <div class="layer5 flex-row">
            <span class="txt9">广告合作客户</span>
          </div>
          <div class="layer6 flex-row">
            <div class="group21 flex-col">
              <div class="group22 flex-col"></div>
            </div>
            <div class="group23 flex-col">
              <div class="wrap3 flex-col"></div>
            </div>
            <div class="group24 flex-col">
              <div class="mod3 flex-col"></div>
            </div>
          </div>
          <div class="layer7 flex-row">
            <div class="wrap4 flex-col">
              <div class="section4 flex-col"></div>
            </div>
            <div class="wrap5 flex-col">
              <div class="layer8 flex-col"></div>
            </div>
            <div class="wrap6 flex-col"><div class="box9 flex-col"></div></div>
          </div>
          <div class="layer9 flex-row">
            <div class="layer10 flex-col">
              <div class="box10 flex-col"></div>
            </div>
            <div class="layer11 flex-col">
              <div class="block2 flex-col"></div>
            </div>
            <div class="layer12 flex-col">
              <div class="main4 flex-col"></div>
            </div>
          </div>
          <div class="layer13 flex-row">
            <div class="wrap7 flex-col"><div class="box11 flex-col"></div></div>
            <div class="wrap8 flex-col"><div class="box12 flex-col"></div></div>
            <div class="wrap9 flex-col"><div class="box13 flex-col"></div></div>
          </div>
          <div class="layer14 flex-row">
            <span class="info15">数据产品合作商</span>
          </div>
          <div class="layer15 flex-row">
            <div class="main5 flex-col">
              <img
                class="label4"
                referrerpolicy="no-referrer"
                src="../..../../assets/img/clogo9.png"
              />
            </div>
            <div class="main6 flex-col">
              <img
                class="label5"
                referrerpolicy="no-referrer"
                src="../..../../assets/img/clogo18.png"
              />
            </div>
            <div class="main7 flex-col"><div class="main8 flex-col"></div></div>
          </div>
          <div class="layer16 flex-row">
            <div class="section5 flex-col">
              <div class="section6 flex-col"></div>
            </div>
            <div class="section7 flex-col">
              <img
                class="label6"
                referrerpolicy="no-referrer"
                src="../..../../assets/img/clogo1.png"
              />
            </div>
            <div class="section8 flex-col">
              <div class="main9 flex-col"></div>
            </div>
          </div>
          <div class="layer17 flex-row">
            <div class="group25 flex-col">
              <div class="main10 flex-col"></div>
            </div>
            <div class="group26 flex-col">
              <img
                class="label7"
                referrerpolicy="no-referrer"
                src="../..../../assets/img/clogo15.png"
              />
            </div>
            <div class="group27 flex-col">
              <div class="mod4 flex-col"></div>
            </div>
          </div>
          <div class="layer18 flex-row">
            <div class="bd4 flex-col"><div class="layer19 flex-col"></div></div>
            <div class="bd5 flex-col"><div class="main11 flex-col"></div></div>
            <div class="bd6 flex-col"><span class="txt10">……</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="outer2 flex-col">
      <div class="section9 flex-col">
        <div class="mod5 flex-col">
          <div class="outer3 flex-col"></div>
          <span class="word16">品牌IP孵化</span>
          <span class="word17">
            以品牌基因为切入点，定制基因裂变系统为客户实现面向消费者的多维度沟通，通过IP运营为企业领袖，代言人，虚拟形象打造话题式、互动式链接碎片化流量的整体解决方案。
          </span>
          <div class="outer4 flex-row">
            <div class="main12 flex-col">
              <span class="word18">角色</span>
              <span class="word19">Role</span>
            </div>
            <span class="word20">R</span>
            <span class="info18">C</span>
            <div class="main13 flex-col">
              <span class="word21">内容</span>
              <span class="word22">Content</span>
            </div>
          </div>
          <div class="outer5 flex-row">
            <div class="box18 flex-col"></div>
            <div class="box19 flex-col"></div>
            <div class="box20 flex-col"></div>
            <div class="box21 flex-col"></div>
          </div>
          <div class="outer6 flex-row">
            <div class="group28 flex-col">
              <div class="layer22 flex-col"></div>
              <div class="layer23 flex-col"></div>
            </div>
            <div class="group29 flex-col">
              <span class="word23">企业新媒体人格化RCSC模型</span>
            </div>
            <div class="group30 flex-col">
              <div class="outer7 flex-col"></div>
              <div class="outer8 flex-col"></div>
            </div>
          </div>
          <div class="outer9 flex-row">
            <span class="word24">场景</span>
            <span class="info19">性格</span>
          </div>
          <div class="outer10 flex-row">
            <span class="word25">Scene</span>
            <span class="txt12">Character</span>
          </div>
          <div class="outer11 flex-row">
            <div class="section10 flex-col"></div>
            <div class="section11 flex-col"></div>
            <div class="section12 flex-col"></div>
            <div class="section13 flex-col"></div>
          </div>
          <div class="outer12 flex-row">
            <div class="mod6 flex-col"></div>
            <div class="mod7 flex-col"></div>
            <div class="mod8 flex-col"></div>
            <div class="mod9 flex-col"></div>
          </div>
          <div class="outer13 flex-col"></div>
          <div class="outer14 flex-col">
            <router-link to="/m_branding">
              <div class="main14 flex-row">
                <span class="txt13">了解详情</span>
                <img
                  class="icon2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/right.png"
                />
              </div>
            </router-link>
          </div>
        </div>
        <span class="txt14" style="transform: scale(0.88)">主营业务</span>
        <span class="txt15" style="transform: scale(0.88)">达人</span>
        <span class="word26" style="transform: scale(0.88)">朋友</span>
        <span class="txt16" style="transform: scale(0.88)">价值观</span>
        <span class="txt17" style="transform: scale(0.88)">生活方式</span>
        <span class="word27" style="transform: scale(0.88)">老师</span>
        <div class="mod10 flex-col"></div>
        <span class="word28" style="transform: scale(0.88)">孩子</span>
        <div class="mod11 flex-col"></div>
        <span class="word29" style="transform: scale(0.88)">品味</span>
        <span class="txt18" style="transform: scale(0.88)">新知</span>
        <span class="word30" style="transform: scale(0.88)">保姆</span>
        <span class="info20" style="transform: scale(0.88)">专业建议</span>
        <span class="word31">S</span>
        <span class="info21">C</span>
        <span class="word32" style="transform: scale(0.88)">购物</span>
        <span class="word33" style="transform: scale(0.88)">度假</span>
        <span class="txt19" style="transform: scale(0.88)">幽默</span>
        <span class="txt20" style="transform: scale(0.88)">亲切</span>
        <span class="txt21" style="transform: scale(0.88)">做饭</span>
        <span class="txt22" style="transform: scale(0.88)">工作</span>
        <span class="word34" style="transform: scale(0.88)">正经</span>
        <span class="word35" style="transform: scale(0.88)">卖萌</span>
        <span class="word36" style="transform: scale(0.88)">高冷</span>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng55a53ca3e0e2507e08da1d3dafe17e69010547e177ef87d9fd59648e2749ac30",
          lanhutext0: "广告情报局",
          lanhuBg3: "#fff",
          lanhutext1:
            "智能测算广告的活跃度、效果和投放金额，有效掌握竞争对手投放策略，洞察行业动态趋势。",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1a0aeee2dc177206da7dfa2bb900296c994459c4e2fefdaa56a6fee029520f32",
          lanhutext0: "电商数据库",
          lanhuBg3: "#fff",
          lanhutext1: "数据监测，推广排行，选品分析，有效转化。",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4cc6e89977b437154a435c740590f5295053bd0b6bca9a997b3cf8d6ed250560",
          lanhutext0: "社媒分析站",
          lanhuBg3: "#fff",
          lanhutext1:
            "各社交媒体数据分析及情报分析，实时追踪各数据，深入分析竞品推广策略，及时掌握行业趋势变化。",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9451f9e8b5d4d285222b16d89db61d2e8784eb1288cbbec680c086aca72d22ee",
          lanhutext0: "投放小管家",
          lanhuBg3: "#fff",
          lanhutext1: "跨平台多账号管理；广告投放自动化。",
        },
      ],
      constants: {},
    };
  },
  components: { countTo },
  methods: {
    // changeRoute(){
    //   this.$router.push('/m_faq');
    // }
  },
  
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
