<template>
  <div class="page flex-col">
    <div class="bd1 flex-col">
      <div class="section2 flex-col">
        <div class="layer1 flex-col">
          <div class="mod1 flex-col">
            <span class="txt2 zoomIn">新媒天成·创森林</span>
            <span class="info2 zoomIn">
              专注为企业与个人提供线上线下创业培训，起盘，咨询服务
            </span>
          </div>
        </div>
      </div>
      <div class="section3 flex-row">
        <div class="group1 flex-col slideInLeft">
          <div class="block1 flex-col">
            <span class="txt3">专注</span>
            <span class="word5">成立至今，专注创业培训服务超10年</span>
          </div>
        </div>
        <div class="group2 flex-col slideInLeft">
          <div class="layer2 flex-col">
            <span class="word6">系统</span>
            <span class="word7">拥有行业内更完善的创业培训系统</span>
          </div>
        </div>
        <div class="group3 flex-col slideInRight">
          <div class="outer1 flex-col">
            <span class="word8">专业</span>
            <span class="txt4">20+创业导师从业经验丰富</span>
          </div>
        </div>
        <div class="group4 flex-col slideInRight">
          <div class="main2 flex-col">
            <span class="txt5">效率</span>
            <span class="info3">服务数百个中小企业，针对性规划</span>
          </div>
        </div>
      </div>
      <span class="word9">关于创森林</span>
      <div class="section4 flex-row">
        <span class="paragraph1">
          『创森林』是新媒天成的旗舰产品，致力于为处于快速发展阶段的创业者提供实战课程与加速服务，帮助广大创业者解决真问题，实现健康、快速成长。
          <br />
          <br />
          <br />
          <br />
          在创森林的学习中，学员将获得认知、资源和资本三重加速&nbsp;，与顶尖企业家、投资人导师论道，与上市公司、独角兽企业家教练叠加势能。
        </span>
        <div class="box1 flex-col">
          <img
            class="img2"
            referrerpolicy="no-referrer"
            src="./assets/img/learn.png"
          />
        </div>
      </div>
      <span class="word10 zoomIn">创森林能帮你解决什么问题</span>
      <div class="imgBox zoomIn">
        <div>
          <img src="./assets/img/img1.png" alt="" srcset="" />
        </div>
        <div>
          <img src="./assets/img/img2.png" alt="" srcset="" />
        </div>
        <div>
          <img src="./assets/img/img3.png" alt="" srcset="" />
        </div>
        <div>
          <img src="./assets/img/img4.png" alt="" srcset="" />
        </div>
        <div>
          <img src="./assets/img/img5.png" alt="" srcset="" />
        </div>
        <div>
          <img src="./assets/img/img6.png" alt="" srcset="" />
        </div>
      </div>
      <span class="word19 zoomIn">为什么选择我们？</span>
      <div style="width: 68%; margin: 35px auto 0" class="zoomIn">
        <img src="./assets/img/img7.png" alt="" style="width: 100%" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style>
.bgbox {
  margin-top: -106px;
}

.imgBox {
  display: flex;
  width: 62%;
  margin: 41px auto 0;
  flex-flow: row wrap;
  justify-content: space-between;
}

.imgBox div {
  width: 32%;
  margin: 19px 0;
  display: flex;
  justify-content: center;
  height: 100%;
}
.imgBox img {
  width: 100%;
  height: 100%;
}

</style>
