import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vue2-animate/dist/vue2-animate.min.css';
// import VueLazyload from 'vue-lazyload'



Vue.config.productionTip = false

router.afterEach(() => {
  window.scrollTo(0,0);
  document.documentElement.scrollTop = 0;
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
  // console.log(document.body.scrollTop)
  // console.log(document.documentElement.scrollTop)

  // console.log('router.afterEach')
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
