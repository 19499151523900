<template>
  <div class="page flex-col">
    <div class="block1 flex-col">
      <div class="section2 flex-col">
        <div class="mod1 flex-col">
          <span class="txt2 zoomIn">打造品牌超级生命体</span>
        </div>
      </div>
      <span class="info3 bounceInUp">
        品牌IP化时代，新媒天成基于市场差异化和品牌需求，从IP内容策划到IP人格塑造，以一套标准化的模式输出，为众多品牌打造独立的人格生命体，让品牌价值裂变，加速商业变现。
      </span>
      <span class="word3 zoomIn">超级生命体打造流程</span>
      <span class="word4 bounceInUp">
        系统孵化&nbsp;/&nbsp;构建IP基础系统&nbsp;/&nbsp;IP品牌化过程
      </span>
      <div class="section3 flex-row zoomIn">
        <div class="layer1 flex-col">
          <div class="mod2 flex-col"><div class="box1 flex-col"></div></div>
          <span class="txt3 zoomIn">商业定位</span>
          <span class="txt4">IP市场/商业</span>
        </div>
        <div class="layer2 flex-row">
          <div class="section4 flex-col"></div>
          <span class="word5 zoomIn">人格塑造</span>
          <span class="word6">IP内容策略/策划</span>
          <div class="section5 flex-col"></div>
        </div>
        <div class="layer3 flex-col">
          <div class="box2 flex-col"></div>
          <span class="txt5 zoomIn">标准输出</span>
          <span class="info4">可复制模式</span>
        </div>
      </div>
      <div class="section6 flex-row zoomIn">
        <div class="main1 flex-col">
          <div class="section7 flex-col"></div>
          <span class="info5">市场调研/商业探测</span>
          <div class="section8 flex-col"></div>
          <span class="info6">超级生命体商业模式构建</span>
          <div class="section9 flex-col"></div>
          <span class="word7">超级生命体差异化定位</span>
        </div>
        <div class="main2 flex-col">
          <div class="group1 flex-col"></div>
          <span class="txt6">超级生命体价值设定系统</span>
          <div class="group2">
            <span class="paragraph1">
              1）三观设定
              <br />
            </span>
            <span class="txt7">-&nbsp;世界观、人生观、价值观／中心思想</span>
            <span class="infoBox1">
              <br />
              2）故事塑造
              <br />
              3）角色塑造
              <br />
            </span>
            <span class="infoBox2">
              -&nbsp;名字、身份、性格
              <br />
              -&nbsp;标签／slogan/口头禅
              <br />
              -&nbsp;传播载体
              <br />
              -&nbsp;流行梗/亮点/槽点
              <br />
              -&nbsp;角色关系
            </span>
            <span class="paragraph2">
              <br />
              4）形象设计
              <br />
              5）品牌化场景规划
            </span>
          </div>
        </div>
        <div class="main3 flex-col">
          <div class="mod3 flex-col"></div>
          <span class="infoBox3">
            超级生命体风格设定系统/
            <br />
            style&nbsp;guide造型规范
          </span>
          <span class="infoBox4">
            -&nbsp;色彩规范
            <br />
            -&nbsp;表情规范
            <br />
            -&nbsp;动态规范
            <br />
            -&nbsp;话术规范
            <br />
            -&nbsp;纹样规范
            <br />
            -&nbsp;道具规范
            <br />
            -&nbsp;场景规范
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
