<template>
  <div>
    <div class="header pc_header" v-if="!mobileFlag">
      <div class="bd2 flex-col">
        <div class="layer1 flex-row">
          <div class="pic1Box">
            <img
              class="pic1"
              referrerpolicy="no-referrer"
              src="../../../src/assets/img/logo.png"
              @click="changeFTitle(1)"
            />
          </div>
          <div class="titleBox">
            <span
              class="info1 title"
              @click="changeFTitle(1)"
              :class="{active: cur1 == 1}"
              >首页</span
            >
            <div @mouseover="interTitle" @mouseout="outerFTitle">
              <!-- <div @mouseover="interTitle"> -->
              <span
                class="word1 title"
                @click="changeFTitle(2)"
                :class="{active: cur1 == 2}"
                >走进新媒天成</span
              >
              <div class="bd3 flex-row" v-show="bg3Flag">
                <div class="main1 flex-col">
                  <div class="outer2 flex-col">
                    <span
                      class="word3"
                      @click="changeSTitle(1)"
                      :class="{active: cur2 == 1}"
                      >公司简介</span
                    >
                    <div
                      style="position: relative"
                      @mouseover="showMain2"
                      @mouseout="levelMain2"
                    >
                      <span
                        class="txt1"
                        @click="changeSTitle(2)"
                        :class="{active: cur2 == 2}"
                        >主要产品与服务</span
                      >
                      <div
                        class="main2 flex-col"
                        v-show="main2Flag"
                        @mouseover="showMain2"
                        @mouseout="levelMain2"
                      >
                        <div class="outer3 flex-col">
                          <span
                            class="txt2"
                            @click="changeTTitle(1)"
                            :class="{active: cur3 == 1}"
                            >新媒体营销</span
                          >
                          <span
                            class="word6"
                            @click="changeTTitle(2)"
                            :class="{active: cur3 == 2}"
                            >品牌IP孵化</span
                          >
                          <span
                            class="word7"
                            @click="changeTTitle(3)"
                            :class="{active: cur3 == 3}"
                            >创森林</span
                          >
                          <span
                            class="txt3"
                            @click="changeTTitle(4)"
                            :class="{active: cur3 == 4}"
                            >投小二</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      class="word5"
                      @click="changeSTitle(3)"
                      :class="{active: cur2 == 3}"
                      >成功案例</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <span
              class="word2 title"
              @click="changeFTitle(3)"
              :class="{active: cur1 == 3}"
              >资讯中心</span
            >
            <span
              class="info2 title"
              @click="changeFTitle(4)"
              :class="{active: cur1 == 4}"
              >联系我们</span
            >
            <span
              class="info3 title"
              @click="changeFTitle(5)"
              :class="{active: cur1 == 5}"
              >加入我们</span
            >
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; width: 100vw">
      <div class="m_header" v-if="mobileFlag">
        <div class="headerBox">
          <div class="header-left">
            <img
              class="logoT"
              referrerpolicy="no-referrer"
              src="../../../src/assets/img/logoT.png"
              @click="changeRoute(0)"
            />
          </div>
          <div class="header-right">
            <img
              class="menu"
              referrerpolicy="no-referrer"
              src="../../../src/assets/img/menuT.png"
              @click="menuFlag = true"
            />
          </div>
        </div>
        <div v-show="openMenuFlag">
          <!-- <div class="menuBox" :class="menuFlag ? 'slideInDown' : 'slideOutUp'"> -->
          <div class="menuBox" v-show="menuFlag">
            <div class="menu-item" @click="changeRoute(0)">首页</div>
            <div class="menu-item" @click="changeRoute(1)">公司简介</div>
            <div style="border-bottom: 1px solid #515660"></div>
            <div class="menu-item" @click="changeRoute(2)">新媒体营销</div>
            <div class="menu-item" @click="changeRoute(3)">品牌IP孵化</div>
            <div class="menu-item" @click="changeRoute(4)">创森林</div>
            <div class="menu-item" @click="changeRoute(5)">投小二</div>
            <div style="border-bottom: 1px solid #515660"></div>
            <div class="menu-item">咨询中心</div>
            <div class="menu-item">联系我们</div>
            <div class="menu-item">加入我们</div>
            <div class="menu-item" @click="menuFlag = false">
              <span class="iconfont icon-xiangshang"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/iconfont.css';
export default {
  data() {
    return {
      openMenuFlag: false,
      menuFlag: false,
      cur1: 1,
      cur2: '',
      cur3: '',
      bg3Flag: false,
      main2Flag: false,
      openFlag: false,
      routerList: ['smomarket', 'branding', 'faq', 'client'],
      mRouterList: ['index', 'about', 'smomarket', 'branding', 'faq', 'client'],
      mobileFlag: false,
    };
  },
  created() {
    var path = this.$route.path;
    if (path == '/pc_index') {
      this.cur1 = 1;
    } else if (
      path == '/pc_smomarket' ||
      path == '/pc_branding' ||
      path == '/pc_faq' ||
      path == '/pc_client' ||
      path == '/pc_about'
    ) {
      this.cur1 = 2;
    }
    this._isMobile();
  },
  watch: {
    menuFlag() {
      this.openMenuFlag = true;
    },
  },
  methods: {
    changeRoute(id) {
      this.$router.push(this.mRouterList[id]);
      this.menuFlag = false;
    },
    changeFTitle(id) {
      if (id == this.cur1);
      else {
        this.cur1 = id;
        this.cur2 = this.cur3 = '';
        if (id == 1) {
          this.$router.push('/index');
          this.bg3Flag = this.main2Flag = false;
        } else if (id == 2) {
          this.bg3Flag = true;
        } else {
          this.bg3Flag = this.main2Flag = false;
        }
      }
    },
    changeSTitle(id) {
      if (id == this.cur2);
      else {
        this.cur1 = 2;
        this.cur2 = id;
        this.cur3 = '';
        if (id == 1) {
          this.$router.push('about');
          this.bg3Flag = false;
        } else if (id == 2) {
          this.main2Flag = true;
        } else {
          this.main2Flag = false;
        }
      }
    },
    changeTTitle(id) {
      this.cur1 = this.cur2 = 2;
      this.cur3 = id;
      this.$router.push(this.routerList[id - 1]);
      this.bg3Flag = this.main2Flag = false;
    },

    interTitle() {
      this.bg3Flag = true;
      this.openFlag = true;
    },
    outerFTitle() {
      this.bg3Flag = false;
    },
    selectSTitle() {
      this.bg3Flag = true;
      this.main2Flag = true;
    },
    levelSTitle() {
      if (this.cur2 != 2 && !this.bg3Flag) {
        this.main2Flag = false;
      }
    },
    levelMain2() {
      this.main2Flag = false;
    },
    showMain2() {
      this.main2Flag = true;
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.mobileFlag = flag;
      return flag;
    },
  },
};
</script>

<style scoped lang="css">
.menuBox {
  /* height: 200px; */
  width: 30%;
  position: absolute;
  right: 0px;
  background: #262933;
  color: #fff;
}
.menu-item {
  text-align: center;
  height: 60px;
  line-height: 60px;
}
.headerBox {
  width: 100%;
  height: 100%;
  padding: 13px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
.m_header {
  width: 100%;
  height: 64px;
  /* background: #000;
   */
  position: relative;
  top: 0;
  z-index: 1001;
}
.logoT {
  height: 38px;
  width: 137px;
}
.menu {
  height: 22px;
  width: 22px;
}
.header {
  height: 96px;
  font-size: 20px;
}
.titleBox {
  display: flex;
  position: relative;
  height: 80%;
  margin-bottom: 20px;
}
.titleBox .title {
  margin-left: 35px;
  height: 100%;
  line-height: 96px;
  cursor: pointer;
}
.active {
  color: rgba(32, 109, 255, 1) !important;
  font-weight: bold !important;
}
.title:hover {
  border-bottom: 1px solid #000;
}
.main1 span:hover {
  /* border-bottom: 1px solid #000; */
  font-weight: bold;
}
.bd2 {
  z-index: 139;
  height: 96px;
  background-color: rgba(255, 255, 255, 1);
  width: 100vw;
  justify-content: center;
  align-items: flex-end;
  /* width: 60%; */
  margin: 0 auto;
}
.layer1 {
  z-index: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pic1 {
  z-index: 140;
  width: 200px;
  height: 100%;
  cursor: pointer;
}
.outer2,
.outer3 span {
  cursor: pointer;
}
.info1 {
  z-index: 183;
  width: 39px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 40, 51, 1);
  font-size: 20px;
  letter-spacing: -0.875px;
  line-height: 28px;
  text-align: left;
  /* margin: 20px 0 0 0; */
}
.word1 {
  z-index: 184;
  height: 70%;
  display: block;
  overflow-wrap: break-word;
  font-size: 20px;
  color: rgba(39, 40, 51, 1);
  letter-spacing: -0.875px;
  font-family: PingFangSC-Semibold;
  line-height: 28px;
  text-align: left;
  /* margin: 20px 0 0 35px; */
}
.word2 {
  z-index: 185;
  width: 77px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 40, 51, 1);
  font-size: 20px;
  letter-spacing: -0.875px;
  line-height: 28px;
  text-align: left;
  /* margin: 20px 0 0 35px; */
}
.info2 {
  z-index: 186;
  width: 77px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 40, 51, 1);
  font-size: 20px;
  letter-spacing: -0.875px;
  line-height: 28px;
  text-align: left;
  /* margin: 20px 0 0 35px; */
}
.info3 {
  z-index: 187;
  width: 77px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 40, 51, 1);
  font-size: 20px;
  letter-spacing: -0.875px;
  line-height: 28px;
  text-align: left;
  /* margin: 20px 0 0 35px; */
}

.bd3 {
  z-index: auto;
  width: 302px;
  height: 265px;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  left: 88px;
}
.main1 {
  z-index: 428;
  height: 140px;
  width: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
}
.outer2 {
  z-index: auto;
  width: 100%;
  height: 100%;
  /* padding: 20px; */
}
.word3 {
  z-index: 430;
  width: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: center;
  margin-top: 20px;
}
.word4 {
  z-index: 431;
  width: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: center;
  margin-top: 22px;
}
.txt1 {
  z-index: 432;
  /* width: 112px; */
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);

  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 22px;
}
.word5 {
  z-index: 433;
  width: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: center;
  margin-top: 22px;
}
.main2 {
  z-index: 435;
  border-radius: 10px;
  background: #fff;
  /* margin-top: 55px; */
  width: 140px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 160px;
  top: -0;
}
.outer3 {
  z-index: auto;
  width: 80px;
  padding: 20px 0;
}
.txt2 {
  z-index: 437;
  width: 80px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.word6 {
  z-index: 438;
  width: 81px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: flex-end;
  margin-top: 22px;
}
.word7 {
  z-index: 439;
  width: 48px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);

  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: center;
  margin-top: 22px;
}
.txt3 {
  z-index: 440;
  width: 48px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(38, 41, 51, 0.6);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  align-self: center;
  margin-top: 22px;
}

@media screen and (max-width: 810px) {
  .pic1Box {
    display: none;
  }
}
</style>
