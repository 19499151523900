<template>
  <div class="page flex-col">
    <div class="group1 flex-col">
      <div class="section2 flex-col">
        <div class="box1 flex-col">
          <span class="word3">投小二适用的客户</span>
        </div>
      </div>
      <span class="word4 zoomIn">
        需要开户投放头部媒体信息流广告，如:快手、巨量引擎、广点通等平台，同时因为投放应用下载/表单转化/电商而频次较高、体量较大的广告主及代理商。
      </span>
      <div class="section3 flex-row zoomIn">
        <div class="outer2 flex-col">
          <span class="txt2">广告主</span>
          <div class="box2 flex-col"></div>
          <div class="box3 flex-col"></div>
          <span class="word5">代理商</span>
        </div>
        <div class="outer3 flex-col"><div class="bd1 flex-col"></div></div>
        <div class="outer4 flex-col">
          <div class="main1 flex-col"></div>
          <div class="main2 flex-col"></div>
          <div class="main3 flex-col"></div>
        </div>
        <div class="outer5 flex-col">
          <div class="group2 flex-col"><span class="info3">快手广告</span></div>
          <div class="group3 flex-col"><span class="word6">巨星引擎</span></div>
          <div class="group4 flex-col"><span class="word7">腾讯广告</span></div>
        </div>
      </div>
      <span class="txt3">广告主/代理商的痛点</span>
      <div class="section4 flex-row">
        <div class="wrap1 flex-col">
          <div class="mod1 flex-col">
            <span class="word8">一对多</span>
            <span class="word9">
              优化师跨平台跨账户管理，搭建不同投放计划，重复度高，效率低。
            </span>
          </div>
        </div>
        <div class="wrap2 flex-col">
          <div class="mod2 flex-col">
            <span class="info4">人力工作繁重</span>
            <span class="word10">
              多平台多账号，汇总数据繁杂，易乱易错，人工实时监控，任务繁重，造成投放事故。
            </span>
          </div>
        </div>
        <div class="wrap3 flex-col">
          <div class="box4 flex-col">
            <span class="word11">KPI难衡量</span>
            <span class="txt4">
              优化师操作手段匮乏，投放效果KPI完成度，难以清晰量化。
            </span>
          </div>
        </div>
      </div>
      <span class="info5">新媒天成·投小二&nbsp;解决方案</span>
      <div class="section5 flex-row">
        <div class="mod3 flex-col">
          <div class="box5 flex-col">
            <div class="outer6 flex-col">
              <img
                class="pic2"
                referrerpolicy="no-referrer"
                src="./assets/img/media4.png"
              />
            </div>
            <span class="txt5">提升效率效果</span>
            <span class="info6">
              帮助优化师从广告运营困境中解脱，自动优化及预警，释放广告投放潜能。
            </span>
          </div>
        </div>
        <div class="mod4 flex-col">
          <div class="mod5 flex-col">
            <div class="main4 flex-col">
              <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/media1.png"
              />
            </div>
            <span class="info7">批量搭建投放</span>
            <span class="word12">
              一天的工作量一小时做完，生成1500个创意仅需15分钟。
            </span>
          </div>
        </div>
        <div class="mod6 flex-col">
          <div class="section6 flex-col">
            <div class="bd2 flex-col">
              <img
                class="pic3"
                referrerpolicy="no-referrer"
                src="./assets/img/media3.png"
              />
            </div>
            <span class="word13">跨账户管理</span>
            <span class="txt6">
              一个智投账号管理多个媒体下的多个账户，绑定媒体账户与同步数据，便捷易操作。
            </span>
          </div>
        </div>
        <div class="mod7 flex-col">
          <div class="box6 flex-col">
            <div class="mod8 flex-col">
              <img
                class="pic4"
                referrerpolicy="no-referrer"
                src="./assets/img/media2.png"
              />
            </div>
            <span class="info8">全链路数据打通</span>
            <span class="word14">
              多维度多指标数据，自由组合，一应俱全，广告/素材/人效数据，一目了然。
            </span>
          </div>
        </div>
      </div>
      <span class="txt7">核心价值</span>
      <div class="section7 flex-row">
        <div class="outer7 flex-col">
          <div class="wrap4 flex-col">
            <span class="txt8">广告主/代理商困境</span>
            <div class="group5 flex-col"></div>
            <span class="paragraph1">
              -&nbsp;优化师手动建投放，效率低下
              <br />
              -&nbsp;创意效果难追踪
              <br />
              -&nbsp;数据报表耗费人力，同步不及时
              <br />
              -&nbsp;广告消耗提升不上去
              <br />
              -&nbsp;投放事故频出
            </span>
          </div>
        </div>
        <div class="outer8 flex-col">
          <div class="box7 flex-col">
            <span class="word15">智投功能</span>
            <div class="main5 flex-col"></div>
            <span class="infoBox1">
              -&nbsp;跨账户批量建创意，单次1500条
              <br />
              -&nbsp;管理素材及效果，明确创意方向&nbsp;
              <br />
              -&nbsp;自动同步投放数据，生成报告
              <br />
              -&nbsp;批量搭建创意，素材效果追踪
              <br />
              -&nbsp;自动预警功能，停止异常投放
            </span>
          </div>
        </div>
        <div class="outer9 flex-col">
          <div class="main6 flex-col">
            <span class="word16">对应效果</span>
            <div class="section8 flex-col"></div>
            <span class="paragraph2">
              -&nbsp;极大提升优化师搭建投放效率
              <br />
              -&nbsp;实时指导素材创作方向
              <br />
              -&nbsp;节约人力，避免信息同步延迟
              <br />
              -&nbsp;提升投放效果，增广告消耗
              <br />
              -&nbsp;减少投放事故
            </span>
          </div>
        </div>
        <div class="outer10 flex-col">
          <div class="group6 flex-col">
            <span class="word17">量化数据</span>
            <div class="mod9 flex-col"></div>
            <span class="infoBox2">
              -&nbsp;效率提升8倍
              <br />
              -&nbsp;效率提升3倍
              <br />
              -&nbsp;效率提升5倍
              <br />
              -&nbsp;营收提升2倍
              <br />
              -&nbsp;事故率减少70％
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
