<template>
  <div class="page flex-col">
    <div class="block1 flex-col">
      <div class="mod1 flex-col">
        <div class="section1 flex-col ">
          <div class="layer1 flex-col zoomIn">
            <span class="word1">SMO社媒营销&nbsp;六大优势</span>
            <span class="word2">
              多角度、多层面，用较短时间快速提升产品知名度，塑造品牌美誉度和公信力
            </span>
          </div>
        </div>
      </div>
      <div class="imgBox">
        <img src="./assets/img/img1.png" alt="" class="slideInLeft" />
        <img src="./assets/img/img2.png" alt="" class="slideInRight" />
        <img src="./assets/img/img3.png" alt="" class="slideInLeft" />
        <img src="./assets/img/img4.png" alt="" class="slideInRight" />
        <img src="./assets/img/img5.png" alt="" class="slideInLeft" />
        <img src="./assets/img/img6.png" alt="" class="slideInRight" />
      </div>
      <span class="word4">为什么选择我们？</span>
      <div style="display: flex" class="itemBox">
        <div class="item">
          <div class="wrap1 flex-col">
            <div class="group1 flex-col" style="padding-left: 2.94vw">
              <span class="word5">1</span>
              <div>
                <span class="paragraph1">
                  专业的
                  <br />
                  数字营销咨询
                </span>
              </div>
            </div>
            <div class="block14 flex-col">
              <span class="word16">
                以多年的行业经验进行服务内容的规划，对每一个运营项目定制合理服务排期，用数字化的方式对每一个运营节点进行控制，确保运营效果。
              </span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="wrap1 flex-col">
            <div class="group1 flex-col" style="padding-left: 2.94vw">
              <span class="word6">2</span>
              <div>
                <span class="infoBox1">
                  成熟的
                  <br />
                  行业解决方案
                </span>
              </div>
            </div>
            <div class="block14 flex-col">
              <span class="word16">
                针对每个项目进行评估和风险预测，确保方案顺利运营。
              </span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="wrap1 flex-col">
            <div class="group1 flex-col" style="padding-left: 2.94vw">
              <span class="word6">3</span>
              <div>
                <span class="infoBox1">
                  优秀的
                  <br />
                  设计能力
                </span>
              </div>
            </div>
            <div class="block14 flex-col">
              <span class="word16">
                以多年的行业经验进行服务内容的规划，对每一个运营项目定制合理服务排期，用数字化的方式对每一个运营节点进行控制，确保运营效果。
              </span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="wrap1 flex-col">
            <div class="group1 flex-col" style="padding-left: 2.94vw">
              <span class="word6">4</span>
              <div>
                <span class="infoBox1"> 资深写手 </span>
              </div>
            </div>
            <div class="block14 flex-col">
              <span class="word16">
                五年以上资深媒体记者或行业评论员出身的文案编辑，擅长深度文章，尤其是对新媒体行业有长期的服务经验。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style scoped>
.itemBox .item {
  margin-bottom: 10px;
}
.imgBox {
  flex-direction: column;
  justify-content: center;
  width: 88%;
  margin: 31px auto 0;
}
.imgBox img {
  width: 100%;
  margin: 5px 0;
}
.itemBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 22px 22px 0;
}
</style>