<template>
  <div class="footer">
    <div v-if="!mobileFlag">
      <div class="outer24 flex-col">
        <div class="group11 flex-row">
          <span class="word56">广东新媒天成科技有限公司</span>
          <span class="txt21">关于我们</span>
          <span class="word57">关于服务</span>
          <span class="word58">关注我们</span>
        </div>
        <div class="group12 flex-row">
          <div class="infoBox1">
            地址：东莞市南城区莞太路111号众创金融街1栋4楼
            <br />
            电话：13790132471
            <br />
            邮箱：xmtc88@126.com
            <br />
            网址：https://www.xmtc88.com

            <div style="margin-top: 1vh; margin-bottom: 3vh">
              新媒天成 版权所有
              <a
                href="https://beian.miit.gov.cn/"
                class="copyright"
                target="_blank"
                >© 粤ICP备2021093252号-1</a
              >
            </div>
          </div>
          <span class="paragraph1">
            <span @click="routeLink(1)" class="pointer">新媒天成</span>
            <br />
            加入我们
            <br />
            新闻动态
          </span>
          <span class="infoBox2">
            <span @click="routeLink(2)" class="pointer"> 社媒营销全案</span>
            <br />

            <span @click="routeLink(3)" class="pointer"> 品牌IP孵化</span>
            <br />

            <span @click="routeLink(5)" class="pointer"> 智能广告投放</span>
            <br />

            <span @click="routeLink(4)" class="pointer"> 创森林创业培训</span>
          </span>
          <div class="group13 flex-col">
            <div class="outer25 flex-col"></div>
            <span class="info21">微信公众号</span>
          </div>
          <div class="group14 flex-col">
            <div class="mod33 flex-col"></div>
            <span class="word59">微博</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mobileFlag">
      <div class="mod10 flex-col">
        <div class="outer1 flex-col">
          <div class="section3 flex-row">
            <span class="info5">微信公众号</span>
            <span class="txt1">微博</span>
          </div>
          <div class="section4 flex-row">
            <div class="layer2 flex-col">
              <img src="../../assets/img/wechat.jpg" alt="" style="height:100%;width:100%" />
            </div>
            <div class="layer3 flex-col">
              <img src="../../assets/img/weibo.png" alt="" style="height:100%;width:100%" />
            </div>
          </div>
          <span class="txt2">
            新媒天成 版权所有
            <a
              href="https://beian.miit.gov.cn/"
              class="copyright"
              target="_blank"
              >© 粤ICP备2021093252号-1</a
            >
            <!-- 新媒天成&nbsp;版权所有&nbsp;©&nbsp;粤ICP备2021093252号-1 -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: ['about', 'smomarket', 'branding', 'faq', 'client'],
      mobileFlag: false,
    };
  },
  created() {
    this._isMobile();
  },
  methods: {
    routeLink(id) {
      this.$router.push(this.routerList[id - 1]);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.mobileFlag = flag;
      return flag;
    },
  },
};
</script>

<style scoped lang="css">
.outer24 {
  z-index: 121;
  height: 15.5vw;
  background-color: rgba(50, 102, 255, 1);
  width: 100vw;
}

.group11 {
  z-index: auto;
  width: 43.7vw;
  height: 1.15vw;
  margin: 2.03vw 0 0 24.47vw;
}

.word56 {
  z-index: 124;
  width: 10vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFangSC-Semibold;
  line-height: 1.15vw;
  text-align: left;
}

.txt21 {
  z-index: 125;
  width: 3.34vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFangSC-Semibold;
  line-height: 1.15vw;
  text-align: left;
  margin-left: 16.41vw;
}
.pointer {
  cursor: pointer;
}
.word57 {
  z-index: 122;
  width: 3.34vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFangSC-Semibold;
  line-height: 1.15vw;
  text-align: left;
  margin-left: 3.13vw;
}

.word58 {
  z-index: 127;
  width: 3.34vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFangSC-Semibold;
  line-height: 1.15vw;
  text-align: left;
  margin-left: 4.17vw;
}

.group12 {
  z-index: auto;
  width: 51.2vw;
  height: 7.3vw;
  margin: 1.45vw 0 0 24.47vw;
}

.infoBox1 {
  z-index: 134;
  width: 20.18vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  line-height: 1.46vw;
  text-align: left;
}

.paragraph1 {
  z-index: 126;
  width: 2.92vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  line-height: 1.46vw;
  text-align: left;
  margin-left: 6.23vw;
}

.infoBox2 {
  z-index: 123;
  width: 5.11vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  line-height: 1.46vw;
  text-align: left;
  margin-left: 3.55vw;
}

.group13 {
  z-index: auto;
  width: 5vw;
  height: 6.88vw;
  margin-left: 2.4vw;
}

.outer25 {
  z-index: 129;
  width: 5vw;
  height: 5vw;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  background: url(../../assets/img/wechat.jpg) 100% no-repeat;
  background-size: contain;
}

.info21 {
  z-index: 130;
  width: 3.65vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  line-height: 1.46vw;
  text-align: center;
  align-self: center;
  margin-top: 0.42vw;
}

.group14 {
  z-index: auto;
  width: 5vw;
  height: 6.88vw;
  margin-left: 0.84vw;
}

.mod33 {
  z-index: 132;
  width: 5vw;
  height: 5vw;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  background: url(../../assets/img/weibo.png) 100% no-repeat;
  background-size: contain;
}

.word59 {
  z-index: 133;
  width: 1.46vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  line-height: 1.46vw;
  text-align: center;
  align-self: center;
  margin-top: 0.42vw;
}
.copyright {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.mod10 {
  z-index: 138;
  height: 42.67vw;
  background-color: rgba(50, 102, 255, 1);
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.outer1 {
  z-index: auto;
  width: 68vw;
  height: 34.67vw;
}

.section3 {
  z-index: auto;
  width: 41.07vw;
  height: 4.27vw;
  margin-left: 10.94vw;
  justify-content: space-between;
}

.info5 {
  z-index: 142;
  width: 16vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 4.27vw;
  text-align: center;
}

.txt1 {
  z-index: 143;
  width: 6.4vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 4.27vw;
  text-align: center;
}

.section4 {
  z-index: auto;
  width: 51.2vw;
  height: 21.34vw;
  justify-content: space-between;
  margin: 1.33vw 0 0 8.26vw;
}

.layer2 {
  z-index: 140;
  width: 21.34vw;
  height: 21.34vw;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}

.layer3 {
  z-index: 141;
  width: 21.34vw;
  height: 21.34vw;
  border-radius: 4px;

  background-color: rgba(255, 255, 255, 1);
}

.txt2 {
  z-index: 139;
  width: 68vw;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 3.74vw;
  text-align: left;
  margin-top: 4vw;
}
</style>
