import Vue from 'vue'
import VueRouter from 'vue-router'


// 默认 to.path 与当前 url 相同会报错
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }


// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

import pc_about from '../views/about/pc_about/index.vue'
import m_about from '../views/about/m_about/index.vue'

import pc_smomarket from '../views/smomarket/pc_smomarket/index.vue'
import m_smomarket from '../views/smomarket/m_smomarket/index.vue'

import pc_client from '../views/client/pc_client/index.vue'
import m_client from '../views/client/m_client/index.vue'

import pc_index from '../views/index/pc_index/index.vue'
import m_index from '../views/index/m_index/index.vue'

import pc_faq from '../views/faq/pc_faq/index.vue'
import m_faq from '../views/faq/m_faq/index.vue'

import pc_branding from '../views/branding/pc_branding/index.vue'
import m_branding from '../views/branding/m_branding/index.vue'




import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/pc_index"
  },
  {
    path: '/pc_index',
    name: 'pc_index',
    component: pc_index
  },
  {
    path: '/m_index',
    name: 'm_index',
    component: m_index
  },
  {
    path: '/pc_about',
    name: 'pc_about',
    component: pc_about
  },
  {
    path: '/m_about',
    name: 'm_about',
    component: m_about
  },
  {
    path: '/pc_smomarket',
    name: 'pc_smomarket',
    component: pc_smomarket
  },
  {
    path: '/m_smomarket',
    name: 'm_smomarket',
    component: m_smomarket
  },
  {
    path: '/pc_client',
    name: 'pc_client',
    component: pc_client
  },
  {
    path: '/m_client',
    name: 'm_client',
    component: m_client
  },
  {
    path: '/pc_faq',
    name: 'pc_faq',
    component: pc_faq
  },
  {
    path: '/m_faq',
    name: 'm_faq',
    component: m_faq
  },
  {
    path: '/pc_branding',
    name: 'pc_branding',
    component: pc_branding
  },
  {
    path: '/m_branding',
    name: 'm_branding',
    component: m_branding
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },

})

function _isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
router.beforeEach((to, from, next) => {

  // // chrome
  // document.body.scrollTop = 0
  // // firefox
  // document.documentElement.scrollTop = 0
  // // safari
  // window.pageYOffset = 0



  var flag = _isMobile() ? '/m_' : '/pc_'
  const {
    path
  } = to;
  if (!path.includes(flag)) {
    var tempath = path.split('_')[1] ? path.split('_')[1] : path.slice(1);
    if (flag + tempath === path) {
      next()
    } else {
      next({ path: flag + tempath })
    }
  } else {
    NProgress.start();
    next()
  }
})
// const drivers = {
//   moblie: '/m_',
//   pc: '/pc_'
// }
// router.beforeEach((to, from, next) => {
//      const {
//       moblie,
//       pc
//      } = drivers
//      // 前缀
//      var prefix = _isMobile() ? moblie : pc
//      // 其余的前缀
//      var other = _isMobile() ? pc : moblie
//     console.log(prefix)
//     console.log(other)
//      const {
//       path
//      } = to;

//      if (!path.includes(prefix)) {
//       // 假设带了其他设备的前缀,去掉, 否则,把前面的/去掉
//       let tempPath = prefix + (path.includes(other) ? path.replace(other, '') : path.slice(1))
//       next({
//         path: tempPath
//       })
//      } else {
//         next()
//      }
// })

router.afterEach(() => {
  // window.scrollTo(0, 0);
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;





  // let bodySrcollTop = document.body.scrollTop
  // if (bodySrcollTop !== 0) {
  //   document.body.scrollTop = 0
  //   return
  // }
  // let docSrcollTop = document.documentElement.scrollTop
  // if (docSrcollTop !== 0) {
  //   document.documentElement.scrollTop = 0
  // }
  window.scrollTo(0, 0);
  NProgress.done()
})


export default router

