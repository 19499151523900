<template>
  <div class="page flex-col">
    <div class="outer1 flex-col">
      <div class="group1 flex-col">
        <div class="outer2 flex-col">
          <div class="layer1 flex-col">
            <span class="info1 zoomIn">新媒天成·创森林</span>
            <span class="word1 zoomIn">
              专注为企业与个人提供线上线下创业培训，起盘，咨询服务
            </span>
          </div>
        </div>
      </div>
      <div class="group2 flex-row">
        <div
          class="section1 flex-col"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <div class="layer2 flex-col">
            <span class="word2" v-html="item.lanhutext0"></span>
            <span class="info2" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <span class="word3">关于创森林</span>
      <div class="group3 flex-col">
        <img
          class="pic1"
          referrerpolicy="no-referrer"
          src="../assets/img/learn.png"
        />
      </div>
      <span class="infoBox1">
        『创森林』是新媒天成的旗舰产品，致力于为处于快速发展阶段的创业者提供实战课程与加速服务，帮助广大创业者解决真问题，实现健康、快速成长。
        <br />
        <br />
        在创森林的学习中，学员将获得认知、资源和资本三重加速&nbsp;，与顶尖企业家、投资人导师论道，与上市公司、独角兽企业家教练叠加势能。
      </span>
      <span class="info3">创森林能帮你解决什么问题</span>
      <div class="imgBox">
        <img src="./assets/img/faq2.webp" alt="" style="width: 100%" />
      </div>
      <span class="word5">为什么选择我们？</span>
      <div style="width: 88%; margin: 35px auto 0">
        <img src="./assets/img/faq1.webp" alt="" style="width: 100%" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        { lanhutext0: "专注", lanhutext1: "成立至今，专注创业培训服务超10年" },
        { lanhutext0: "系统", lanhutext1: "拥有行业内更完善的创业培训系统" },
        { lanhutext0: "专业", lanhutext1: "20+创业导师从业经验丰富" },
        { lanhutext0: "效率", lanhutext1: "服务数百个中小企业，针对性规划" },
      ],
      constants: {},
    };
  },
  created() {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    // console.log(123)
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style scoped>
.imgBox {
  display: flex;
  width: 84%;
  margin: 1vh auto 0;
  flex-flow: row wrap;
  justify-content: space-between;
}

.imgBox div {
  width: 32%;
  margin: 19px 0;
  display: flex;
  justify-content: center;
  height: 100%;
}
.imgBox img {
  width: 100%;
  height: 100%;
}
</style>
