<template>
  <div class="page flex-col">
    <div class="layer1 flex-col">

      <div class="group1 flex-col">
        <div class="outer1 flex-col">
          <div class="layer2 flex-col">
            <!-- <img
              class="pic1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng762420820ac8bc83b199336bd3d7a278682a49c40c4bca85c392ae4d6e480e09.png"
            /> -->
            <span class="info1">打造品牌超级生命体</span>
          </div>
        </div>
      </div>
      <span class="info2">
        品牌IP化时代，新媒天成基于市场差异化和品牌需求，从IP内容策划到IP人格塑造，以一套标准化的模式输出，为众多品牌打造独立的人格生命体，让品牌价值裂变，加速商业变现。
      </span>
      <span class="info3">超级生命体打造流程</span>
      <span class="word1">
        系统孵化&nbsp;/&nbsp;构建IP基础系统&nbsp;/&nbsp;IP品牌化过程
      </span>
      <div class="group2 flex-col">
        <!-- <img
          class="icon1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng12d644430a93ff58a320dc49bd1df1cb924638bc1227aaa92e546b21d33800bc.png"
        /> -->
      </div>
      <span class="word2">商业定位</span>
      <span class="info4">IP市场/商业</span>
      <div class="group3 flex-col"></div>
      <span class="word3">市场调研/商业探测</span>
      <div class="group4 flex-col"></div>
      <span class="txt1">超级生命体商业模式构建</span>
      <div class="group5 flex-col"></div>
      <span class="info5">超级生命体差异化定位</span>
      <div class="group6 flex-col">
        <!-- <img
          class="icon2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng12d644430a93ff58a320dc49bd1df1cb924638bc1227aaa92e546b21d33800bc.png"
        /> -->
      </div>
      <span class="word4">人格塑造</span>
      <span class="word5">IP内容策略/策划</span>
      <div class="group7 flex-col"></div>
      <span class="txt2">超级生命体价值设定系统</span>
      <div class="group8 flex-col">
        <div class="section1">
          <span class="paragraph1">
            1）三观设定
            <br />
          </span>
          <span class="txt3">-&nbsp;世界观、人生观、价值观／中心思想</span>
          <span class="paragraph2">
            <br />
            2）故事塑造
            <br />
            3）角色塑造
            <br />
          </span>
          <span class="paragraph3">
            -&nbsp;名字、身份、性格
            <br />
            -&nbsp;标签／slogan/口头禅
            <br />
            -&nbsp;传播载体
            <br />
            -&nbsp;流行梗/亮点/槽点
            <br />
            -&nbsp;角色关系
          </span>
          <span class="paragraph4">
            <br />
            4）形象设计
            <br />
            5）品牌化场景规划
          </span>
        </div>
      </div>
      <div class="group9 flex-col">
        <!-- <img
          class="label1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng12d644430a93ff58a320dc49bd1df1cb924638bc1227aaa92e546b21d33800bc.png"
        /> -->
      </div>
      <span class="word6">标准输出</span>
      <span class="word7">可复制模式</span>
      <div class="group10 flex-col"></div>
      <span class="infoBox1">
        超级生命体风格设定系统/
        <br />
        style&nbsp;guide造型规范
      </span>
      <div class="group11 flex-col">
        <span class="paragraph5">
          -&nbsp;色彩规范&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;表情规范
          <br />
          -&nbsp;动态规范&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;话术规范
          <br />
          -&nbsp;纹样规范&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;道具规范
          <br />
          -&nbsp;场景规范
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />